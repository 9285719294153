@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #888;
}

p {
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 24px;
}

* {
  padding: 0;
  margin: 0;
}

.navbar-toggler:focus,
a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  border-color: none !important;
  outline: none;
  outline: none !important;
  box-shadow: none !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: $blue;
  border-color: $blue;
}

select {
  box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  flex-align: center;
  align-items: center;
  margin: 0;
  font: 400 14px;
  color: #081828;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: pre;
  cursor: default;
  background-color: -internal-light-dark(white, #3b3b3b);
  border-color: -internal-light-dark(#767676, #858585);
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  border-image: initial;
  border-image: initial;
  appearance: menulist;
  appearance: menulist;
  appearance: menulist;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  box-align: center;
  -webkit-rtl-ordering: logical;
}

span,
a {
  display: inline-block;
  text-decoration: none;
  transition: all .4s ease;
  transition: all .4s ease;
  transition: all .4s ease;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 600;
  color: #081828;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mt-225 {
  margin-top: 225px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mb-225 {
  margin-bottom: 225px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

.img-bg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 450px;
  }
}

.breadcrumbs {
  position: relative;
  z-index: 2;
  padding-top: 180px;
  padding-bottom: 100px;
  text-align: left;
  background-color: $blue;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    padding-top: 120px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .breadcrumbs {
    padding-top: 120px;
    padding-bottom: 60px;
  }
}

.breadcrumbs .breadcrumbs-content {
  position: relative;
  text-align: center;
}

.breadcrumbs .breadcrumbs-content .page-title {
  position: relative;
  font-size: 32px;
  font-weight: 700;
  line-height: 50px;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs .breadcrumbs-content .page-title {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (max-width: 767px) {
  .breadcrumbs .breadcrumbs-content .page-title {
    font-size: 25px;
    line-height: 38px;
  }
}

.breadcrumbs .breadcrumbs-content .breadcrumb-nav {
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
  background: 0 0;
  border-radius: 0;
}

.breadcrumbs .breadcrumb-nav {
  margin-top: 15px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs .breadcrumb-nav {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .breadcrumbs .breadcrumb-nav {
    margin-top: 8px;
  }
}

.breadcrumbs .breadcrumb-nav li {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  text-transform: capitalize;
}

.breadcrumbs .breadcrumb-nav li::after {
  position: absolute;
  top: 4px;
  right: -7px;
  font-family: lineIcons;
  font-size: 10px;
  content: "\ea5c";
}

.breadcrumbs .breadcrumb-nav li:last-child {
  padding: 0;
  margin: 0;
}

.breadcrumbs .breadcrumb-nav li:last-child::after {
  display: none;
}

.breadcrumbs .breadcrumb-nav li,
.breadcrumbs .breadcrumb-nav li a {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.breadcrumbs .breadcrumb-nav li a {
  position: relative;
}

.breadcrumbs .breadcrumb-nav li a:hover {
  text-decoration: underline;
}

.section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section-title {
  position: relative;
  z-index: 5;
  padding: 0 100px;
  margin-bottom: 80px;
  text-align: center;
  h2 {
    position: relative;
    margin-bottom: 25px;
    font-size: 34px;
    font-weight: 800;
    line-height: 42px;
    text-transform: capitalize;
  }
  h3 {
    display: block;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    color: $blue;
    text-transform: uppercase;
  }
  p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    padding: 0 200px;
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    padding: 0 20px;
    margin-bottom: 70px;
    h2 {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 32px;
    }
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }
}

@media (max-width: 767px) {
  .section-title {
    padding: 0 10px;
    margin-bottom: 60px;
    h2 {
      margin-bottom: 18px;
      font-size: 20px;
      line-height: 30px;
    }
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }
}

.section-title.align-right {
  padding: 0;
  padding-left: 600px;
}

.section-title.align-right h2::before {
  display: none;
}

.section-title.align-right h2::after {
  position: absolute;
  right: 0;
  bottom: -1px;
  width: 50px;
  height: 2px;
  content: "";
  background: $blue;
}

.section-title.align-left {
  padding: 0;
  padding-right: 600px;
}

.section-title.align-left h2::before {
  left: 0;
  margin-left: 0;
}

.scroll-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 9;
  display: box;
  display: flexbox;
  display: flex;
  flex-align: center;
  flex-pack: center;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  font-size: 14px;
  line-height: 45px;
  color: #fff !important;
  cursor: pointer;
  background: $blue;
  border-radius: 5px;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  box-pack: center;
  box-align: center;
}

.scroll-top:hover {
  background-color: #081828;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, .15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, .15) !important;
  transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
}

.overlay {
  position: relative;
  z-index: 1;
}

.overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background: #081828;
  opacity: .4;
  transition: all .4s ease;
  transition: all .4s ease;
}

.pagination {
  display: block;
  margin: 40px 0 0;
  text-align: left;
}

.pagination.center {
  text-align: center;
}

.pagination.right {
  text-align: right;
}

.pagination.left {
  text-align: left;
}

.pagination .pagination-list {
  display: inline-block;
  overflow: hidden;
}

.pagination .pagination-list li {
  display: inline-block;
  margin-top: 10px;
  margin-right: 5px;
}

.pagination .pagination-list li:last-child {
  margin-right: 0;
}

.pagination .pagination-list li a {
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 400;
  color: #081828;
  text-align: center;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
}

.pagination .pagination-list li.active a,
.pagination .pagination-list li:hover a {
  color: #fff;
  background: $blue;
  border-color: transparent;
}

.pagination .pagination-list li a i {
  font-size: 13px;
}

.blog-grids.pagination {
  margin-top: 50px;
  text-align: center;
}

.button .btn {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 13px 30px;
  margin-right: 7px;
  overflow: hidden;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  background-color: $blue;
  border: none;
  border-radius: 5px;
  transition: .2s;
  transition: .2s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500;
  }
}

@media (max-width: 767px) {
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500;
  }
}

.button .btn i {
  display: inline-block;
  margin-right: 5px;
}

.button .btn:last-child {
  margin: 0;
}

.button .btn:hover {
  color: #fff;
  background-color: #5a29c8;
  box-shadow: 0 4px 4px #0000000f;
  box-shadow: 0 4px 4px #0000000f;
}

.button .btn-alt {
  color: #fff !important;
  background-color: #081828 !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .button .btn-alt {
    padding: 10px 30px;
  }
}

@media (max-width: 767px) {
  .button .btn-alt {
    padding: 10px 30px;
  }
}

.button .btn-alt:hover {
  color: #fff !important;
  background-color: $blue !important;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preloader-icon {
  display: inline-block;
  width: 100px;
  height: 100px;
  padding: 0;
}

.preloader-icon span {
  position: absolute;
  display: inline-block;
  width: 100px;
  height: 100px;
  background: $blue;
  border-radius: 100%;
  animation: preloader-fx 1.6s linear infinite;
  animation: preloader-fx 1.6s linear infinite;
}

.preloader-icon span:last-child {
  animation-delay: -.8s;
  animation-delay: -.8s;
}

@keyframes preloader-fx {
  0% {
    opacity: .5;
    transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes preloader-fx {
  0% {
    opacity: .5;
    transform: scale(0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(1, 1);
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  width: 100%;
  background: 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header {
    padding: 18px 0 !important;
  }
  .header .mobile-menu-btn .toggler-icon {
    background-color: #fff;
  }
  .header .button {
    margin: 0 !important;
  }
  .header .navbar-collapse {
    position: absolute;
    top: 67px !important;
    left: 0;
    z-index: 9;
    width: 100%;
    max-height: 350px;
    padding: 10px 20px;
    overflow-y: scroll;
    background-color: #fff;
    border-top: 1px solid #eee;
    border-radius: 6px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, .1);
  }
  .header .navbar .navbar-nav .nav-item a:hover {
    color: $blue !important;
  }
  .header .navbar .navbar-nav .nav-item a.active {
    color: $blue !important;
  }
  .header .navbar-nav .nav-item {
    margin: 0;
  }
  .header .navbar-nav .nav-item:hover a {
    color: $blue;
  }
  .header .navbar-nav .nav-item a {
    padding: 12px 16px !important;
  }
  .header .navbar-nav .nav-item a::before {
    display: none;
  }
  .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    padding: 0;
    margin-right: 15px;
    margin-left: 15px;
    visibility: visible;
    border: none;
    box-shadow: none;
    box-shadow: none;
    opacity: 1;
  }
  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 12px;
  }
  .header .navbar-nav .nav-item .sub-menu .nav-item a:hover {
    color: $blue !important;
  }
  .header .navbar-nav .nav-item a {
    display: box;
    display: flexbox;
    display: flex;
    flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
    color: #051441;
    box-pack: justify;
  }
  .header .navbar-nav .nav-item a::after {
    visibility: visible;
    opacity: 1;
  }
  .header .navbar-nav .nav-item .sub-menu li.active {
    color: $blue !important;
    background: #fff !important;
  }
  .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }
  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 10px 12px !important;
  }
  .header .navbar-nav .nav-item .sub-menu li:hover {
    color: $blue !important;
    background: #fff !important;
  }
  .header .navbar-nav .nav-item a {
    font-size: 14px;
  }
  .header .navbar-nav .nav-item a:hover {
    color: $blue;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 18px 0 !important;
  }
  .header .mobile-menu-btn .toggler-icon {
    background-color: #fff;
  }
  .header .navbar-collapse {
    position: absolute;
    top: 58px !important;
    left: 0;
    z-index: 9;
    width: 100%;
    max-height: 350px;
    padding: 10px 20px;
    overflow-y: scroll;
    background-color: #fff;
    border-top: 1px solid #eee;
    border-radius: 6px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, .1);
  }
  .header .navbar .navbar-nav .nav-item a:hover {
    color: $blue !important;
  }
  .header .navbar .navbar-nav .nav-item a.active {
    color: $blue !important;
  }
  .header .navbar-nav .nav-item {
    margin: 0;
  }
  .header .navbar-nav .nav-item:hover a {
    color: $blue;
  }
  .header .navbar-nav .nav-item a {
    padding: 12px 16px !important;
  }
  .header .navbar-nav .nav-item a::before {
    display: none;
  }
  .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    padding: 0;
    margin-right: 15px;
    margin-left: 15px;
    visibility: visible;
    border: none;
    box-shadow: none;
    box-shadow: none;
    opacity: 1;
  }
  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 12px;
  }
  .header .navbar-nav .nav-item .sub-menu .nav-item a:hover {
    color: $blue !important;
  }
  .header .navbar-nav .nav-item a {
    display: box;
    display: flexbox;
    display: flex;
    flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
    color: #051441;
    box-pack: justify;
  }
  .header .navbar-nav .nav-item a::after {
    visibility: visible;
    opacity: 1;
  }
  .header .navbar-nav .nav-item .sub-menu li.active {
    color: $blue !important;
    background: #fff !important;
  }
  .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }
  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 10px 12px !important;
  }
  .header .navbar-nav .nav-item .sub-menu li:hover {
    color: $blue !important;
    background: #fff !important;
  }
  .header .navbar-nav .nav-item a {
    font-size: 14px;
  }
  .header .navbar-nav .nav-item a:hover {
    color: $blue;
  }
}

@media (max-width: 767px) {
  .header .button {
    display: none;
  }
}

.header .button .btn {
  padding: 12px 30px;
  font-weight: 500;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
}

.header .button .btn:hover {
  color: $blue;
  background-color: #fff;
}

.header .navbar-brand img {
  width: 250px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header .navbar-brand img {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .header .navbar-brand img {
    width: 250px;
  }
}

.header.sticky .navbar-brand .white-logo {
  visibility: hidden;
  opacity: 0;
}

.header.sticky .navbar-brand .dark-logo {
  visibility: visible;
  opacity: 1;
}

.header.sticky .button .btn {
  color: #fff;
  background-color: $blue;
  border-color: transparent;
}

.header.sticky .button .btn:hover {
  color: #fff;
  background-color: #081828;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .05);
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .05);
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.navbar-expand-lg .navbar-nav {
  margin: 0;
  //margin-right: auto !important;
  //margin-left: auto !important;
}

.header .navbar .navbar-nav .nav-item a.active {
  color: #fff;
}

.sticky .navbar .navbar-nav .nav-item a.active {
  color: $blue;
}

.sticky .navbar .navbar-nav .nav-item a {
  color: #081828;
}

.header .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff;
}

.sticky .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff;
}

.sticky .navbar .mobile-menu-btn .toggler-icon {
  background: #333;
}

.navbar-area {
  z-index: 99;
  width: 100%;
  padding: 0;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.navbar-area.sticky {
  position: fixed;
  z-index: 99;
  padding: 0;
  background: $blue;
  background: #fff;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .05);
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .05);
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.navbar {
  position: relative;
  padding: 0;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.navbar-brand {
  padding-left: 0;
  border-radius: 0;
}

.mobile-menu-btn {
  padding: 0;
  border: 0;
}

.mobile-menu-btn:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  box-shadow: none;
}

.mobile-menu-btn .toggler-icon {
  position: relative;
  display: block;
  width: 30px;
  height: 2px;
  margin: 5px 0;
  background-color: #222;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(1) {
  top: 7px;
  transform: rotate(45deg);
  transform: rotate(45deg);
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(3) {
  top: -7px;
  transform: rotate(135deg);
  transform: rotate(135deg);
}

.navbar-nav .nav-item {
  position: relative;
  z-index: 1;
  margin-right: 40px;
}

//.navbar-nav .nav-item:last-child {
//  margin-right: 0 !important;
//}

.navbar-nav .nav-item:hover a {
  color: #fff;
}

.sticky .navbar-nav .nav-item:hover a {
  color: $blue;
}

.navbar-nav .nav-item a {
  position: relative;
  position: relative;
  display: inline-box;
  display: inline-flexbox;
  display: inline-flex;
  flex-align: center;
  align-items: center;
  padding: 35px 0;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  box-align: center;
}

.navbar-nav .nav-item a::after {
  visibility: hidden;
  opacity: 0;
}

.navbar-nav .nav-item:hover a::before {
  width: 100%;
}

.navbar-nav .nav-item a.active {
  color: #fff;
}

.navbar-nav .nav-item a.dd-menu::after {
  position: absolute;
  top: 50%;
  right: 17px;
  height: 10px;
  margin-top: 0;
  margin-left: 5px;
  font: normal normal normal 1em/1 "LineIcons";
  font-size: 10px;
  content: "\ea58";
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item a.dd-menu::after {
    right: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item a.dd-menu::after {
    top: 18px;
    right: 0;
    transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item a.collapsed::after {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.navbar-nav .nav-item:hover > .sub-menu {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

.navbar-nav .nav-item:hover > .sub-menu .sub-menu {
  top: 0;
  left: 100%;
}

.navbar-nav .nav-item .sub-menu {
  position: absolute;
  top: 110% !important;
  left: 0;
  min-width: 240px;
  padding: 30px;
  visibility: hidden;
  background: #fff;
  border-radius: 0 0 4px 4px;
  border-radius: 5px;
  box-shadow: 0 5px 20px #0000001a;
  box-shadow: 0 5px 20px #0000001a;
  opacity: 0;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.navbar-nav .nav-item:hover .sub-menu {
  top: 100% !important;
}

.navbar-nav .nav-item .sub-menu .nav-item {
  width: 100%;
  margin-bottom: 15px;
}

.navbar-nav .nav-item .sub-menu .nav-item:last-child {
  margin: 0;
}

.navbar-nav .nav-item .sub-menu .nav-item a {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  color: #888;
  text-transform: capitalize;
  border-radius: 4px;
  transition: all .1s ease;
  transition: all .1s ease;
}

.navbar-nav .nav-item .sub-menu .nav-item a:hover {
  color: $blue;
}

.navbar-nav .nav-item .sub-menu.left-menu {
  left: -100%;
}

.navbar-nav .nav-item .sub-menu.collapse:not(.show) {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: none;
  }
}

.navbar-nav .nav-item .sub-menu > li {
  display: block;
  margin-left: 0;
}

.navbar-nav .nav-item .sub-menu > li:last-child {
  border: none;
}

.navbar-nav .nav-item .sub-menu > li.active > a {
  color: $blue !important;
}

.navbar-nav .nav-item .sub-menu > li > a {
  display: block;
  padding: 12px 15px;
  font-size: 14px;
  font-weight: 400;
  font-weight: 400;
  color: #222;
}

.navbar-nav .nav-item .sub-menu > li:first-child a {
  border: none;
}

.add-list-button {
  display: inline-block;
  margin-left: 10px;
}

.add-list-button .btn i {
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
}

.hero-area {
  position: relative;
  padding: 220px 0 250px;
  background-image: url(../images/hero/hero-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area {
    padding: 150px 0 180px;
  }
}

@media (max-width: 767px) {
  .hero-area {
    padding: 120px 0 70px;
  }
}

.hero-area.overlay::before {
  background: $blue;
  opacity: .9;
}

.hero-area .hero-content {
  position: relative;
  z-index: 1;
  padding: 0 100px;
  overflow: hidden;
  text-align: center;
  border-radius: 0;
}

.hero-area .hero-content h5 {
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.hero-area .hero-content h1 {
  font-size: 48px;
  font-weight: 800;
  line-height: 50px;
  color: #fff;
  text-shadow: 0 3px 8px #00000017;
  text-transform: capitalize;
}

.hero-area .hero-content h1 span {
  display: block;
}

.hero-area .hero-content p {
  margin-top: 30px;
  font-size: 15px;
  color: #fff;
}

.hero-area .hero-content .button {
  margin-top: 40px;
}

.hero-area .hero-content .button .btn {
  margin-right: 12px;
  color: $blue;
  background-color: #fff;
}

.hero-area .hero-content .button .btn:hover {
  color: #fff;
  background-color: #081828;
}

.hero-area .hero-content .button .btn.btn-alt {
  color: #fff;
  background-color: #ffffff6b;
}

.hero-area .hero-content .button .btn.btn-alt:hover {
  color: $blue !important;
  background-color: #fff !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-content {
    text-align: center;
  }
  .hero-area .hero-content h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
  .hero-area .hero-content p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .hero-area .hero-content {
    padding: 0 10px;
  }
  .hero-area .hero-content h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .hero-area .hero-content p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
  }
  .hero-area .hero-content .button .btn {
    width: 60%;
    margin: 0;
    margin-bottom: 7px;
  }
  .hero-area .hero-content .button .btn:last-child {
    margin: 0;
  }
}

.small-features .inner-content {
  position: relative;
  z-index: 5;
  display: inline-block;
  display: inline-block;
  width: 100%;
  margin-top: -50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small-features .inner-content {
    margin-top: -80px;
  }
}

@media (max-width: 767px) {
  .small-features .inner-content {
    margin-top: 0;
  }
}

.small-features .single-feature {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 50px 40px;
  padding-left: 100px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .048);
  box-shadow: 0 0 15px rgba(0, 0, 0, .048);
  transition: all .5s cubic-bezier(.68, -.55, .27, 1.55) 0s;
  transition: all .5s cubic-bezier(.68, -.55, .27, 1.55) 0s;
}

.small-features .single-feature:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, .048);
  box-shadow: 0 10px 15px rgba(0, 0, 0, .048);
  transform: translateY(-5px);
  transform: translateY(-5px);
}

.small-features .single-feature i {
  position: absolute;
  top: 50%;
  left: 40px;
  font-size: 35px;
  color: $blue;
  transform: translateY(-50%);
  transform: translateY(-50%);
}

.small-features .single-feature h2 {
  font-size: 16px;
  font-weight: 600;
  color: #081828;
}

.small-features .single-feature h2 span {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small-features .single-feature {
    padding: 40px 30px;
    text-align: center;
  }
  .small-features .single-feature i {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    margin-bottom: 15px;
    transform: none;
    transform: none;
  }
  .small-features .single-feature h2 {
    font-size: 15px;
  }
}

.features {
  background-color: #fff;
}

.features .section-title {
  margin-bottom: 30px;
}

.features .single-feature {
  position: relative;
  padding: 35px;
  margin-top: 30px;
  text-align: left;
  background-color: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 6px;
  transition: all .5s cubic-bezier(.68, -.55, .27, 1.55) 0s;
  transition: all .5s cubic-bezier(.68, -.55, .27, 1.55) 0s;
}

.features .single-feature:hover {
  box-shadow: 0 3px 5px #00000017;
  box-shadow: 0 3px 5px #00000017;
  transform: translateY(-5px);
  transform: translateY(-5px);
}

.features .single-feature i {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  background-color: $blue;
  border-radius: 7px;
  box-shadow: 0 4px 6px #0000002a;
  box-shadow: 0 4px 6px #0000002a;
}

.features .single-feature h3 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}

.features .single-feature p {
  line-height: 22px;
}

.about-us {
  background-color: #f9f9f9;
}

.about-us .content-left {
  position: relative;
}

.about-us .content-left img {
  width: 100%;
  border-radius: 5px;
}

.about-us .content-left .video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  padding-left: 3px;
  margin-top: -40px;
  margin-left: -40px;
  font-size: 16px;
  line-height: 80px;
  color: $blue;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.about-us .content-left .video:hover {
  color: #fff;
  background-color: $blue;
  transform: scale(1.05);
  transform: scale(1.05);
}

.about-us .content-left .video::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border: 1px solid #fff;
  border-radius: 50%;
  animation: pulse-border-2 2s linear infinite;
  animation: pulse-border-2 2s linear infinite;
}

@keyframes pulse-border {
  0% {
    opacity: 1;
    transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
    transform: scale(1.3);
  }
}

@keyframes pulse-border {
  0% {
    opacity: 1;
    transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
    transform: scale(1.3);
  }
}

@keyframes pulse-border-2 {
  0% {
    opacity: 1;
    transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes pulse-border-2 {
  0% {
    opacity: 1;
    transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
    transform: scale(1.5);
  }
}

.about-us .content-right {
  padding-left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .about-us .content-right {
    padding: 0;
    margin-top: 30px;
  }
}

.about-us .content-right h2 {
  position: relative;
  display: block;
  margin-bottom: 25px;
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us .content-right h2 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 767px) {
  .about-us .content-right h2 {
    font-size: 22px;
    line-height: 35px;
  }
}

.about-us .content-right h3 {
  position: relative;
  margin-top: 40px;
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 600;
  line-height: 45px;
  text-transform: capitalize;
}

.about-us .content-right p {
  font-size: 14px;
  line-height: 26px;
}

.about-us .content-right .list {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .about-us .content-right .list {
    margin-top: 30px;
  }
}

.about-us .content-right .list li {
  position: relative;
  display: block;
  padding-left: 25px;
  margin-bottom: 12px;
  font-size: 14px;
  color: #777;
}

.about-us .content-right .list li i {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  font-size: 15px;
  color: $blue;
}

.about-us .content-right .list li:last-child {
  margin: 0;
}

.about-us .content-right .button {
  margin-top: 50px;
}

.our-achievement {
  padding: 130px 0;
  text-align: center;
  background-color: $blue;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our-achievement {
    padding: 80px 0;
  }
}

@media (max-width: 767px) {
  .our-achievement {
    padding: 60px 0;
  }
}

.our-achievement .title h2 {
  margin-bottom: 10px;
  font-size: 35px;
  font-weight: 700;
  color: #fff;
}

.our-achievement .title p {
  color: #fff;
}

.our-achievement .single-achievement {
  padding: 0 10px;
  margin-top: 50px;
  text-align: center;
}

.our-achievement .single-achievement h3 {
  display: block;
  margin-bottom: 5px;
  font-size: 35px;
  font-weight: 800;
  color: #fff;
}

.our-achievement .single-achievement p {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .our-achievement .title h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .our-achievement .single-achievement {
    margin-top: 30px;
  }
  .our-achievement .single-achievement h3 {
    font-size: 28px;
  }
}

.testimonials {
  position: relative;
  overflow: hidden;
  background-color: #f9f9f9;
}

.testimonials .tns-nav {
  position: absolute;
  bottom: 80px;
  left: 50%;
  z-index: 9;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonials .tns-nav {
    bottom: 50px;
  }
}

@media (max-width: 767px) {
  .testimonials .tns-nav {
    bottom: 40px;
  }
}

.testimonials .tns-nav button {
  display: inline-block;
  width: 14px;
  height: 6px;
  margin: 0 5px;
  background-color: #081828;
  border: none;
  border-radius: 5px;
  transition: all .4s ease;
  transition: all .4s ease;
}

.testimonials .tns-nav button:hover {
  background: $blue;
}

.testimonials .tns-nav button.tns-nav-active {
  width: 25px;
  background: $blue;
}

.testimonials .testi-patern1 {
  position: absolute;
  bottom: -90px;
  left: -200px;
  width: 300px;
}

@media (max-width: 767px) {
  .testimonials .testi-patern1 {
    display: none;
  }
}

.testimonials .testi-patern2 {
  position: absolute;
  top: -90px;
  right: -200px;
  width: 300px;
}

@media (max-width: 767px) {
  .testimonials .testi-patern2 {
    display: none;
  }
}

.testimonials .single-testimonial {
  padding: 0 80px 40px;
  text-align: center;
}

@media (max-width: 767px) {
  .testimonials .single-testimonial {
    padding: 0 10px 20px 20px;
  }
}

.testimonials .single-testimonial .text .brand-icon {
  margin-bottom: 30px;
}

.testimonials .single-testimonial .text .brand-icon img {
  width: 140px;
}

.testimonials .single-testimonial .text p {
  font-size: 17px;
  font-weight: 600;
  line-height: 28px;
  color: #081828;
}

@media (max-width: 767px) {
  .testimonials .single-testimonial .text p {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
  }
}

.testimonials .single-testimonial .author {
  position: relative;
  display: inline-block;
  margin-top: 30px;
}

.testimonials .single-testimonial .author img {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.testimonials .single-testimonial .author .name {
  display: block;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #081828;
}

.testimonials .single-testimonial .author .name span {
  display: block;
  margin-top: 4px;
  font-size: 13px;
  color: #777;
}

.pricing-table {
  background-color: #f9f9f9;
}

.pricing-table .section-title {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .pricing-table .section-title {
    margin-bottom: 30px;
  }
}

.pricing-table .single-table {
  margin-top: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  transition: all .5s cubic-bezier(.68, -.55, .27, 1.55) 0s;
  transition: all .5s cubic-bezier(.68, -.55, .27, 1.55) 0s;
}

.pricing-table .single-table:hover:hover {
  box-shadow: 0 3px 5px #00000017;
  box-shadow: 0 3px 5px #00000017;
  transform: translateY(-5px);
  transform: translateY(-5px);
}

.pricing-table .single-table .table-head {
  padding: 25px;
  border-bottom: 1px solid #eee;
}

.pricing-table .single-table .table-head .title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.pricing-table .single-table .table-head p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.pricing-table .single-table .table-head .price {
  padding: 30px 0;
}

.pricing-table .single-table .table-head .price .amount {
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
}

.pricing-table .single-table .table-head .price .amount .duration {
  display: inline-block;
  margin-left: 3px;
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.pricing-table .single-table .table-head .button .btn {
  width: 100%;
  padding: 12px 30px;
  font-size: 13px;
  background-color: #081828;
}

.pricing-table .single-table .table-head .button .btn:hover {
  color: #fff;
  background-color: $blue;
}

.pricing-table .single-table .table-content {
  padding: 25px;
}

.pricing-table .single-table .table-content .middle-title {
  margin-bottom: 25px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.pricing-table .single-table .table-content .table-list li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 13px;
  font-size: 14px;
}

.pricing-table .single-table .table-content .table-list li:last-child {
  margin: 0;
}

.pricing-table .single-table .table-content .table-list li i {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 14px;
  color: $blue;
}

.faq {
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq {
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .faq {
    padding-bottom: 30px;
  }
}

.accordion-item:first-of-type .accordion-button {
  padding-right: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.accordion-item .accordion-button {
  display: block;
  width: 100%;
  padding: 20px;
  padding-right: 40px;
  overflow: hidden;
  font-size: 17px;
  font-weight: 500;
  border: none;
  border: 1px solid #eee;
  border-radius: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .accordion-item .accordion-button {
    padding: 18px 20px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .accordion-item .accordion-button {
    padding: 15px 20px;
    padding-right: 40px;
  }
}

.accordion-item .accordion-button .title {
  position: relative;
  float: left;
  padding-left: 45px;
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .accordion-item .accordion-button .title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .accordion-item .accordion-button .title {
    font-size: 14px;
  }
}

.accordion-item .accordion-button .title .serial {
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 28px;
  color: #081828;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%);
}

.accordion-item .accordion-button i {
  position: relative;
  position: absolute;
  top: 0;
  top: 50%;
  right: 20px;
  font-size: 13px;
  transition: all .3s ease;
  transition: all .3s ease;
  transform: translateY(-50%);
  transform: translateY(-50%);
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: $blue;
  border-color: transparent;
  border-radius: 8px 8px 0 0;
}

.accordion-button:not(.collapsed) .serial {
  color: $blue;
  background-color: #fff;
  border-color: transparent;
}

.accordion-button:not(.collapsed) i::before {
  font-family: lineIcons;
  content: "\eb2c";
}

.accordion-button::after {
  display: none;
}

.accordion-collapse {
  border: none;
}

.accordion-body {
  padding: 40px;
  background-color: #f9f9f9;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  border-radius: 0 0 8px 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .accordion-body {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .accordion-body {
    padding: 20px;
  }
}

.accordion-body p {
  margin: 0;
  margin-bottom: 20px;
  color: #777;
}

.accordion-body p:last-child {
  margin: 0;
}

.accordion-item {
  margin-bottom: 20px;
}

.call-action {
  padding: 80px 0;
  background-color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-action {
    padding: 60px 0;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .call-action {
    padding: 50px 0;
    text-align: center;
  }
}

.call-action .text h2 {
  font-size: 32px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-action .text h2 {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .call-action .text h2 {
    font-size: 22px;
  }
}

.call-action .text h2 span {
  display: block;
  color: $blue;
}

.call-action .button {
  float: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-action .button {
    float: none;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .call-action .button {
    float: none;
    margin-top: 25px;
  }
}

.blog-section {
  background-color: #f9f9f9;
}

.blog-section .section-title {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-section .section-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .blog-section .section-title {
    margin-bottom: 20px;
  }
}

.blog-section .single-blog {
  margin-top: 30px;
  overflow: hidden !important;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .164);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .164);
}

.blog-section .single-blog .blog-img {
  overflow: hidden;
}

.blog-section .single-blog .blog-img a {
  width: 100%;
}

.blog-section .single-blog .blog-img img {
  width: 100%;
  transition: all .3s ease;
  transition: all .3s ease;
}

.blog-section .single-blog:hover .blog-img img {
  transform: scale(1.1);
  transform: scale(1.1);
}

.blog-section .single-blog .blog-content {
  padding: 30px;
}

.blog-section .single-blog .blog-content .category {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: $blue;
}

.blog-section .single-blog .blog-content h4 {
  display: block;
  margin-top: 5px;
  line-height: 25px;
}

.blog-section .single-blog .blog-content h4 a {
  display: inline-block;
  font-size: 19px;
  color: #081828;
}

.blog-section .single-blog .blog-content h4 a:hover {
  color: $blue;
}

.blog-section .single-blog .blog-content p {
  display: block;
  margin-top: 20px;
}

.blog-section .single-blog .blog-content .autor {
  position: relative;
  padding-left: 62px;
  margin-top: 30px;
}

.blog-section .single-blog .blog-content .autor img {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.blog-section .single-blog .blog-content .autor .name {
  display: inline-block;
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

.blog-section .single-blog .blog-content .autor .name:hover {
  color: $blue;
}

.blog-section .single-blog .blog-content .autor .meta-content {
  margin-left: 4px;
}

.blog-section .single-blog .blog-content .autor .meta-content li {
  position: relative;
  display: inline-block;
  padding-right: 10px;
  margin-right: 10px;
}

.blog-section .single-blog .blog-content .autor .meta-content li a {
  font-size: 13px;
  font-weight: 500;
  color: #888;
}

.blog-section .single-blog .blog-content .autor .meta-content li a:hover {
  color: $blue;
}

.blog-section .single-blog .blog-content .autor .meta-content li::before {
  position: absolute;
  top: 50%;
  right: -5px;
  width: 5px;
  height: 5px;
  content: "";
  background-color: #d2d2d2;
  border-radius: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%);
}

.blog-section .single-blog .blog-content .autor .meta-content li:last-child {
  padding: 0;
  margin: 0;
}

.blog-section .single-blog .blog-content .autor .meta-content li:last-child::before {
  display: none;
}

.blog-list {
  background: #f9f9f9;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .blog-list .pagination {
    margin-top: 20px;
  }
}

.blog-list .single-blog {
  margin: 0;
}

.blog-list .single-blog {
  margin-bottom: 30px;
}

.blog-single {
  background: #f9f9f9;
}

.blog-single .single-inner .main-content-head {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
}

.blog-single .meta-information {
  padding: 40px;
  background-color: #fff;
}

@media (max-width: 767px) {
  .blog-single .meta-information {
    padding: 25px;
  }
}

.blog-single .meta-info {
  margin-top: 25px;
}

.blog-single .meta-info li {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .blog-single .meta-info li {
    margin-bottom: 5px;
  }
  .blog-single .meta-info li::before {
    display: none;
  }
  .blog-single .meta-info li:first-child {
    display: block;
    margin-bottom: 15px;
  }
}

.blog-single .meta-info li::before {
  position: absolute;
  top: 50%;
  right: -5px;
  width: 5px;
  height: 5px;
  content: "";
  background-color: #d2d2d2;
  border-radius: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%);
}

.blog-single .meta-info li:last-child {
  padding: 0;
  margin: 0;
}

.blog-single .meta-info li:last-child::before {
  display: none;
}

.blog-single .meta-info li a {
  font-size: 14px;
  font-weight: 500;
  color: #888;
}

.blog-single .meta-info li a i {
  display: inline-block;
  margin-right: 2px;
}

.blog-single .meta-info li a:hover {
  color: $blue;
}

.blog-single .meta-info li a img {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 50%;
}

.blog-single .post-thumbnils {
  position: relative;
  overflow: hidden;
  border-radius: 0;
}

.blog-single .post-thumbnils img {
  width: 100%;
}

.blog-single .detail-inner {
  padding: 40px;
  background-color: #fff;
}

@media (max-width: 767px) {
  .blog-single .detail-inner {
    padding: 25px;
  }
}

.blog-single .post-title {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 32px;
}

@media (max-width: 767px) {
  .blog-single .post-title {
    line-height: 26px;
  }
}

.blog-single .post-title a {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-single .post-title a {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .blog-single .post-title a {
    font-size: 18px;
  }
}

.blog-single .post-title a:hover {
  color: $blue;
}

.blog-single p {
  margin: 20px 0;
  font-size: 14px;
  line-height: 26px;
}

.blog-single p:first-child {
  margin-top: 0;
}

.blog-single h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.blog-single .list {
  margin: 30px 0;
}

.blog-single .list li {
  position: relative;
  display: block;
  padding-left: 25px;
  margin-bottom: 15px;
}

.blog-single .list li:last-child {
  margin-bottom: 0;
}

.blog-single .list li i {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 15px;
  color: #fff;
  color: $blue;
}

blockquote {
  position: relative;
  z-index: 1;
  padding: 40px;
  margin: 40px 0;
  overflow: hidden;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: center;
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 0;
  border-radius: 8px;
}

@media (max-width: 767px) {
  blockquote {
    padding: 20px;
  }
}

blockquote .icon i {
  display: block;
  margin-bottom: 20px;
  font-size: 32px;
  color: $blue;
}

@media (max-width: 767px) {
  blockquote .icon i {
    margin-bottom: 15px;
  }
}

blockquote h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #081828;
}

@media (max-width: 767px) {
  blockquote h4 {
    font-size: 14px;
  }
}

blockquote span {
  display: block;
  margin-top: 20px;
  font-size: 13px;
  color: #888;
}

.post-social-media {
  margin-top: 40px;
}

.post-social-media .share-title {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}

.post-details .post-social-media ul {
  display: inline-block;
  margin-left: 30px;
}

@media (max-width: 767px) {
  .post-details .post-social-media ul {
    display: block;
    margin-top: 8px;
    margin-left: 0;
  }
}

.post-details .post-social-media ul li {
  display: inline-block;
  margin-right: 15px;
}

.post-details .post-social-media ul li a {
  position: relative;
  font-size: 14px;
  color: #999;
}

.post-details .post-social-media ul li a:hover {
  color: $blue;
}

.post-details .post-social-media ul li a span {
  position: absolute;
  top: -30px;
  left: 50%;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  visibility: hidden;
  background-color: $blue;
  border-radius: 4px;
  opacity: 0;
  transition: all .4s ease;
  transition: all .4s ease;
  transform: translateX(-50%);
  transform: translateX(-50%);
}

.post-details .post-social-media ul li a span::before {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  content: "";
  border: 5px solid $blue;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.post-details .post-social-media ul li a:hover span {
  top: -35px;
  visibility: visible;
  opacity: 1;
}

.post-comments {
  padding: 40px;
  margin-top: 40px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
}

.comment-title {
  position: relative;
  z-index: 1;
  margin-bottom: 40px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.post-comments .comments-list li {
  position: relative;
  padding: 30px;
  padding-left: 140px;
  font-size: 14px;
  background-color: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .post-comments .comments-list li {
    padding: 25px;
    padding-left: 0;
  }
}

.post-comments .comments-list li .comment-img {
  position: absolute;
  left: 30px;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .post-comments .comments-list li .comment-img {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 12px;
  }
}

.post-comments .comments-list li .comment-img img {
  max-width: 80px;
  max-height: 80px;
  border: 3px solid #eee;
  border-radius: 50%;
}

.post-comments .comments-list li .comment-desc .desc-top {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.post-comments .comments-list li .comment-desc .desc-top h6 {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}

.post-comments .comments-list li .comment-desc .desc-top span.date {
  font-size: 14px;
  font-weight: 400;
}

.post-comments .comments-list li .comment-desc .desc-top .reply-link {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  color: #888;
  background-color: transparent;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .post-comments .comments-list li .comment-desc .desc-top .reply-link {
    position: relative;
    display: block;
    margin-top: 15px;
  }
}

.post-comments .comments-list li .comment-desc .desc-top .reply-link:hover {
  color: $blue;
}

.post-comments .comments-list li .comment-desc .desc-top .reply-link i {
  margin-right: 5px;
}

.post-comments .comments-list li .comment-desc p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.post-comments .comments-list li.children {
  margin-left: 130px;
}

@media (max-width: 767px) {
  .post-comments .comments-list li.children {
    margin: 0;
  }
}

.post-comments .comments-list li:not(:first-child) {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #eee;
}

.comment-form {
  padding: 40px;
  margin-top: 40px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
}

.comment-reply-title {
  position: relative;
  z-index: 1;
  margin-bottom: 40px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.comment-form form .form-box {
  position: relative;
}

.comment-form form .form-box .icon {
  position: absolute;
  top: 17px;
  right: 25px;
  font-size: 16px;
}

.comment-form form .form-box .form-control-custom {
  height: 50px;
  padding: 0 25px;
  margin-bottom: 20px;
  font-size: 14px;
  font-size: 14px;
  font-weight: 500;
  font-weight: 400;
  color: #081828;
  background: #f9f9f9;
  border: none;
  border: 1px solid #eee;
  border-radius: 5px;
}

.comment-form form .form-box textarea.form-control-custom {
  height: 200px;
  padding: 25px;
}

.comment-form form .form-box .formcustom::input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.comment-form form .form-box .formcustom:input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.comment-form form .form-box .formcustom::input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.comment-form form .form-box .form-control-custom::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.sidebar .widget {
  padding: 40px;
  margin-bottom: 30px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .sidebar .widget:first-child {
    margin-top: 30px;
  }
}

.sidebar .widget:last-child {
  margin-bottom: 0;
}

.sidebar .widget .widget-title {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.sidebar .widget.search-widget form {
  position: relative;
}

.sidebar .widget.search-widget form input {
  width: 100%;
  height: 55px;
  padding: 0 70px 0 30px;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  border: 1px solid #eee;
  border-radius: 5px;
}

.sidebar .widget.search-widget form input::input-placeholder {
  color: #333;
}

.sidebar .widget.search-widget form input:input-placeholder {
  color: #333;
}

.sidebar .widget.search-widget form input::input-placeholder {
  color: #333;
}

.sidebar .widget.search-widget form input::placeholder {
  color: #333;
}

.sidebar .widget.search-widget form button {
  position: absolute;
  top: 6px;
  right: 7px;
  z-index: 1;
  width: 42px;
  height: 42px;
  padding: 0 !important;
  font-size: 13px;
  color: #fff !important;
  color: #fff;
  background: $blue;
  border: none;
  border: none;
  border-radius: 5px;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.sidebar .widget.search-widget form button:hover {
  color: #fff;
  background-color: #081828;
}

.sidebar .widget.popular-feeds .single-popular-feed {
  display: box;
  display: flexbox;
  display: flex;
  flex-align: center;
  align-items: center;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eee;
  box-align: center;
}

.sidebar .widget.popular-feeds .single-popular-feed:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc {
  position: relative;
  padding-left: 100px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .cetagory {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  color: #888;
  border-radius: 5px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .cetagory:hover {
  color: $blue;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .feed-img {
  position: absolute;
  top: 5px;
  left: 0;
  overflow: hidden;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .feed-img img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title {
  margin-bottom: 12px;
  line-height: 1.5;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title a {
  font-size: 14px;
  font-weight: 500;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title a:hover {
  color: $blue;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time {
  font-size: 13px;
  font-weight: 400;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time i {
  margin-right: 4px;
}

.sidebar .widget.categories-widget ul li:last-child {
  margin-bottom: 0;
}

.sidebar .widget.categories-widget ul li:first-child a {
  padding-top: 0;
}

.sidebar .widget.categories-widget ul li:last-child a {
  padding-bottom: 0;
  border: none;
}

.sidebar .widget.categories-widget ul li a {
  display: block;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 500;
  color: #888;
  border-bottom: 1px solid #eee;
}

.sidebar .widget.categories-widget ul li a:hover {
  padding-left: 10px;
  color: $blue;
}

.sidebar .widget.popular-tag-widget {
  padding-bottom: 35px;
}

.popular-tag-widget .tags > a {
  display: inline-box;
  display: inline-flexbox;
  display: inline-flex;
  flex-pack: center;
  justify-content: center;
  padding: 7px 15px;
  margin-right: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  color: #888;
  text-transform: capitalize;
  background: $blue;
  background: 0 0;
  border: 1px solid #eee;
  border-radius: 5px;
  box-pack: center;
}

.popular-tag-widget .tags > a:hover {
  color: #fff;
  background-color: $blue;
  border-color: transparent;
}

.sidebar .widget.help-call .inner {
  text-align: left;
}

.sidebar .widget.help-call .inner h3 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  color: #081828;
  color: $blue;
}

.sidebar .widget.help-call .inner h3 span {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #081828;
}

.client-logo-section {
  padding: 60px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .client-logo-section {
    padding: 40px 0;
  }
}

.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo {
  padding: 10px 20px;
  margin: auto;
  text-align: center;
}

.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo img {
  width: 100%;
  max-width: 180px;
  opacity: .8;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo img:hover {
  opacity: 1;
}

.services {
  position: relative;
  padding: 220px 0 250px;
  background-image: url("../images/hero/whatwedo.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.services.overlay::before {
  background: #081828;
  opacity: .9;
}

.services.style2 {
  background-color: #fff;
  background-image: none;
}

.services.style2 .section-title {
  margin-bottom: 20px;
}

.services.style2 .section-title h2 {
  color: #081828;
}

.services.style2 .section-title h3 {
  color: $blue;
}

.services.style2 .section-title p {
  color: #888;
}

.services.style2 .single-service i {
  color: #fff;
  background-color: $blue;
}

.services.style2 .single-service h3 a {
  color: #081828;
}

.services.style2 .single-service h3 a:hover {
  color: $blue;
}

.services.style2 .single-service p {
  color: #888;
}

.services .section-title {
  margin-bottom: 20px;
}

.services .section-title h2 {
  color: #fff;
}

.services .section-title h3 {
  color: #fff;
}

.services .section-title p {
  color: #fff;
}

.services .single-service {
  padding-right: 70px;
  margin-top: 80px;
  border-radius: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .services .single-service {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .services .single-service {
    margin-top: 50px;
  }
}

.services .single-service i {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 50px;
  color: $blue;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
}

.services .single-service h3 {
  margin-bottom: 15px;
}

.services .single-service h3 a {
  font-size: 19px;
  font-weight: 700;
  color: #fff;
}

.services .single-service p {
  color: #fff;
}

.service-details .details-content {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
}

.service-details .details-content .innter-content {
  padding: 40px;
}

@media (max-width: 767px) {
  .service-details .details-content .innter-content {
    padding: 25px;
  }
}

.service-details .details-content .title {
  font-size: 26px;
  font-weight: 700;
  line-height: 38px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details .details-content .title {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .service-details .details-content .title {
    font-size: 18px;
    line-height: 28px;
  }
}

.service-details .details-content .sub-title {
  margin: 25px 0;
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .service-details .details-content .sub-title {
    font-size: 18px;
  }
}

.service-details .details-content p {
  display: block;
  margin: 20px 0;
  line-height: 24px;
}

.service-details .details-content p:first-child {
  margin-top: 0;
}

.service-details .details-content p:last-child {
  margin-bottom: 0;
}

.service-details .details-content .list li {
  position: relative;
  display: block;
  padding-left: 25px;
  margin-bottom: 15px;
}

.service-details .details-content .list li:last-child {
  margin: 0;
}

.service-details .details-content .list li i {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 15px;
  color: #fff;
  color: $blue;
}

.service-sidebar .single-widget {
  padding: 40px;
  margin-bottom: 30px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .service-sidebar .single-widget:first-child {
    margin-top: 30px;
  }
}

.service-sidebar .single-widget h3 {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.service-sidebar .single-widget:last-child {
  margin: 0;
}

.service-sidebar .single-widget.service-category ul li a {
  position: relative;
  display: block;
  padding: 15px 0;
  padding-top: 15px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #888;
  border-bottom: 1px solid #eee;
}

.service-sidebar .single-widget.service-category ul li a:hover {
  padding-left: 8px;
  color: $blue;
}

.service-sidebar .single-widget.service-category ul li a i {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 16px;
  transform: translateY(-50%);
  transform: translateY(-50%);
}

.service-sidebar .single-widget.service-category ul li:last-child a {
  border: none;
}

.service-sidebar .single-widget.pdf-download {
  background-image: url(../images/service/pdf-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 2px solid #fff;
}

.service-sidebar .single-widget.pdf-download h4 {
  font-size: 16px;
  font-weight: 600;
}

.service-sidebar .single-widget.pdf-download h4 span {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: $blue;
}

.service-sidebar .single-widget.pdf-download .button {
  margin-top: 25px;
}

.service-sidebar .single-widget.pdf-download .button .btn {
  font-size: 14px;
}

.service-sidebar .single-widget.service-cta .support-icon {
  margin-bottom: 15px;
}

.service-sidebar .single-widget.service-cta .support-icon i {
  font-size: 35px;
  color: $blue;
}

.service-sidebar .single-widget.service-cta h4 {
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 22px;
  border-bottom: 1px solid #eee;
}

.service-sidebar .single-widget.service-cta ul li {
  display: block;
  margin-bottom: 5px;
}

.service-sidebar .single-widget.service-cta ul li:last-child {
  margin: 0;
}

.service-sidebar .single-widget.service-cta ul li a {
  font-size: 16px;
  font-weight: 500;
  color: #888;
}

.service-sidebar .single-widget.service-cta ul li a:hover {
  color: $blue;
}

.service-sidebar .single-widget.service-cta .button {
  margin-top: 30px;
}

.team .section-title {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .team .section-title {
    margin-bottom: 30px;
  }
}

.team .single-team {
  margin-top: 30px;
  overflow: hidden;
  border-radius: 10px;
  transition: all .5s cubic-bezier(.68, -.55, .27, 1.55) 0s;
  transition: all .5s cubic-bezier(.68, -.55, .27, 1.55) 0s;
}

.team .single-team:hover:hover {
  box-shadow: 0 3px 5px #00000017;
  box-shadow: 0 3px 5px #00000017;
  transform: translateY(-5px);
  transform: translateY(-5px);
}

.team .single-team .image img {
  width: 100%;
}

.team .single-team .content {
  padding: 25px 30px 30px;
  border: 1px solid #eee;
  border-radius: 0 0 10px 10px;
}

.team .single-team .text h3 a {
  font-size: 17px;
  font-weight: 600;
  color: #081828;
}

.team .single-team .text h5 {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  color: $blue;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .team .single-team .text {
    text-align: center;
  }
}

.team .single-team .social {
  display: inline-block;
  float: right;
}

.team .single-team .social li {
  display: inline-block;
  margin-right: 10px;
}

.team .single-team .social li:last-child {
  margin: 0;
}

.team .single-team .social li a {
  color: #666;
}

.team .single-team .social li a:hover {
  color: $blue;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .team .single-team .social {
    display: block;
    float: none;
    margin-top: 20px;
    text-align: center;
  }
}

.portfolio-section {
  background-color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-section .section-title {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .portfolio-section .section-title {
    margin-bottom: 40px;
  }
}

.portfolio-section .portfolio-btn-wrapper {
  margin-bottom: 0;
  text-align: center;
}

.portfolio-button-head {
  position: relative;
  width: 100%;
  height: 100%;
}

.portfolio-section .grid {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-section .grid {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .portfolio-section .grid {
    margin-top: 40px;
  }
}

.portfolio-section .portfolio-btn-wrapper button {
  padding: 7px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  color: #051441;
  background: 0 0;
  border: none;
  border: 1px solid #eee;
  border-radius: 30px;
  transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.portfolio-section .portfolio-btn-wrapper button:hover {
  color: #fff;
  background-color: $blue;
  border-color: transparent;
}

.portfolio-section .portfolio-btn-wrapper button.active {
  color: #fff;
  background: $blue !important;
  border-color: transparent;
}

.portfolio-section .portfolio-btn-wrapper button:last-child {
  margin-right: 0;
}

.portfolio-item-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 0;
}

.portfolio-item-wrapper .portfolio-img {
  position: relative;
  z-index: 5;
  overflow: hidden;
}

.portfolio-item-wrapper .portfolio-img::before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  z-index: 1;
  width: 100%;
  height: 100%;
  visibility: hidden;
  content: "";
  background-color: $blue;
  opacity: 0;
}

.portfolio-item-wrapper:hover .portfolio-img::before {
  visibility: visible;
  opacity: .9;
  transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.portfolio-item-wrapper .portfolio-img img {
  width: 100%;
  transition: all .4s ease;
  transition: all .4s ease;
  transition: all .4s ease;
  transform: scale(1.1);
  transform: scale(1.1);
}

.portfolio-item-wrapper:hover .portfolio-img img {
  transform: scale(1);
  transform: scale(1);
}

.portfolio-section .pf-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  z-index: 9;
  width: 100%;
  height: 100%;
  padding: 30px;
  text-align: left;
  visibility: hidden;
  opacity: 0;
}

.portfolio-section .pf-content .more-icon {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 40px;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  color: $blue;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
}

.portfolio-section .pf-content .more-icon:hover {
  color: $blue;
  background-color: #fff;
  border-color: transparent;
}

.portfolio-section .pf-content .category {
  display: inline-block;
  padding: 3px 12px;
  margin-bottom: 12px;
  font-size: 13px;
  font-weight: 400;
  color: #081828;
  background-color: #fff;
  border-radius: 30px;
  transform: translateY(-30px);
  transform: translateY(-30px);
}

.portfolio-section .pf-content h4 a {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  transform: translateY(-50px);
  transform: translateY(-50px);
}

.portfolio-section .pf-content h4 a:hover {
  opacity: .8;
}

.portfolio-item-wrapper:hover .pf-content {
  visibility: visible;
  opacity: 1;
  transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.portfolio-item-wrapper:hover .pf-content .category {
  transition: all .3s ease;
  transition: all .3s ease;
  transform: none;
  transform: none;
}

.portfolio-item-wrapper:hover .pf-content h4 a {
  transition: all .3s ease;
  transition: all .3s ease;
  transform: none;
  transform: none;
}

.portfolio-details .content {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
}

.portfolio-details .content .inner-content {
  padding: 40px;
}

@media (max-width: 767px) {
  .portfolio-details .content .inner-content {
    padding: 25px;
  }
}

.portfolio-details .content .meta-info {
  margin-top: 40px;
}

.portfolio-details .content .meta-info ul {
  display: inline-block;
  padding: 25px 0;
  border: 1px solid #eee;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .portfolio-details .content .meta-info ul {
    width: 100%;
  }
}

.portfolio-details .content .meta-info ul li {
  display: inline-block;
  padding: 0 30px;
  font-size: 15px;
  font-weight: 600;
  color: #081828;
  border-right: 1px solid #eee;
}

@media (max-width: 767px) {
  .portfolio-details .content .meta-info ul li {
    display: block;
    margin-bottom: 20px;
    border: none;
  }
  .portfolio-details .content .meta-info ul li:last-child {
    margin: 0;
  }
}

.portfolio-details .content .meta-info ul li:last-child {
  border: none;
}

.portfolio-details .content .meta-info ul li span {
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #888;
}

.portfolio-details .content .meta-info ul li .button .btn {
  position: relative;
  margin-top: -28px;
}

@media (max-width: 767px) {
  .portfolio-details .content .meta-info ul li .button .btn {
    margin: 0;
  }
}

.portfolio-details .content .category {
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #888;
}

@media (max-width: 767px) {
  .portfolio-details .content .category {
    font-size: 13px;
  }
}

.portfolio-details .content img {
  width: 100%;
}

.portfolio-details .content .title {
  font-size: 28px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details .content .title {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .portfolio-details .content .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.portfolio-details .content .sub-title {
  margin: 25px 0;
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .portfolio-details .content .sub-title {
    margin: 20px 0;
    font-size: 20px;
  }
}

.portfolio-details .content .min-title {
  margin: 25px 0;
  font-size: 22px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .portfolio-details .content .min-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .portfolio-details .content .min-title {
    margin: 20px 0;
  }
}

.portfolio-details .content p {
  display: block;
  margin: 20px 0;
  line-height: 24px;
}

.portfolio-details .content p:first-child {
  margin-top: 0;
}

.portfolio-details .content p:last-child {
  margin-bottom: 0;
}

.portfolio-details .content .image-block {
  margin: 40px 0;
}

.portfolio-details .content .image-block img {
  width: 100%;
}

@media (max-width: 767px) {
  .portfolio-details .content .image-block img {
    margin: 15px 0;
  }
}

.portfolio-details .content .grid-list p {
  padding-right: 50px;
}

.contact-us {
  position: relative;
  background-color: #fff;
}

.contact-us .section-title {
  margin-bottom: 50px;
}

.contact-us .contact-info .single-info {
  position: relative;
  padding: 40px;
  padding-left: 100px;
  margin-top: 30px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
  box-shadow: 0 1px 20px 0 rgba(32, 32, 32, .11);
  transition: all .3s ease;
  transition: all .3s ease;
}

.contact-us .contact-info .single-info:hover {
  background-color: $blue;
}

.contact-us .contact-info .single-info:hover i {
  color: #fff;
}

.contact-us .contact-info .single-info:hover h3 {
  color: #fff;
}

.contact-us .contact-info .single-info:hover ul li {
  color: #fff;
}

.contact-us .contact-info .single-info:hover ul li a {
  color: #fff;
}

.contact-us .contact-info .single-info:hover ul li a:hover {
  color: #fff;
  opacity: .8 !important;
}

.contact-us .contact-info .single-info i {
  position: absolute;
  top: 40px;
  left: 40px;
  font-size: 30px;
  color: $blue;
  transition: all .3s ease;
  transition: all .3s ease;
}

.contact-us .contact-info .single-info h3 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #081828;
  transition: all .3s ease;
  transition: all .3s ease;
}

.contact-us .contact-info .single-info ul li {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  transition: all .3s ease;
  transition: all .3s ease;
}

.contact-us .contact-info .single-info ul li:last-child {
  margin: 0;
}

.contact-us .contact-info .single-info ul li a {
  font-weight: 500;
  color: #888;
  transition: all .3s ease;
  transition: all .3s ease;
}

.contact-us .contact-info .single-info ul li a:hover {
  color: $blue;
}

.contact-form-head {
  position: relative;
  z-index: 2;
  background-image: url("../images/contact/contact-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.contact-form-head::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 60%;
  height: 100%;
  content: "";
  background-color: $blue;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .contact-form-head::before {
    width: 100%;
  }
}

.contact-form-head .contact-inner-title h2 {
  display: block;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-head .contact-inner-title h2 {
    font-size: 25px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .contact-form-head .contact-inner-title h2 {
    font-size: 20px;
    line-height: 30px;
  }
}

.contact-form-head .contact-inner-title p {
  color: #fff;
}

.contact-form-head .contact-inner-title .question {
  display: block;
  min-height: 70px;
  margin-top: 40px;
}

.contact-form-head .contact-inner-title .question img {
  float: left;
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border-radius: 70px;
}

.contact-form-head .contact-inner-title .question h4 {
  padding-top: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.contact-form-head .contact-inner-title .question h4 span {
  display: block;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
}

.contact-form-head .contact-inner-title .day-list {
  display: block;
  margin-top: 40px;
}

.contact-form-head .contact-inner-title .day-list li {
  display: block;
  margin-bottom: 3px;
  font-weight: 500;
  color: #fff;
}

.contact-form-head .contact-inner-title .day-list li:last-child {
  margin: 0;
}

.contact-form-head .contact-inner-title .call-back {
  display: inline-block;
  margin-top: 30px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
}

.contact-form-head .contact-inner-title .call-back i {
  display: inline-block;
  margin-left: 5px;
  font-size: 15px;
}

.contact-form-head .form-main {
  padding: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 30px 0 rgba(32, 32, 32, .11);
  box-shadow: 0 1px 30px 0 rgba(32, 32, 32, .11);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .contact-form-head .form-main {
    padding: 35px;
    margin-top: 40px;
  }
}

.contact-form-head .form-main .form-title {
  margin-bottom: 30px;
}

.contact-form-head .form-main .form-title h2 {
  margin-bottom: 35px;
  font-size: 22px;
}

.contact-form-head .form-main .form-title p {
  font-size: 16px;
}

.contact-form-head .form-main .form .form-group {
  display: block;
  margin-bottom: 15px;
}

.contact-form-head .form-main .form .form-group input {
  width: 100%;
  height: 52px;
  padding: 0 20px;
  font-weight: 400;
  line-height: 52px;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #e6e2f5;
  border-radius: 5px;
}

.contact-form-head .form-main .form .form-group textarea {
  width: 100%;
  height: 180px;
  padding: 15px 20px;
  font-weight: 400;
  color: #333;
  resize: none;
  resize: vertical;
  background-color: #f9f9f9;
  border: 1px solid #e6e2f5;
  border-radius: 5px;
}

.contact-form-head .form-main .form .form-group.button {
  width: 100%;
  margin-bottom: 0;
}

.contact-form-head .form-main .form .form-group.button .btn {
  width: 100%;
  height: 50px;
  border: none;
}

.map-section {
  background-color: #f9f9f9;
}

.map-section .mapouter {
  height: 500px;
}

.map-section .mapouter .gmap_canvas {
  height: 500px;
}

.map-section .mapouter .gmap_canvas iframe {
  height: 500px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .map-section .mapouter {
    height: 400px;
  }
  .map-section .mapouter .gmap_canvas {
    height: 400px;
  }
  .map-section .mapouter .gmap_canvas iframe {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .map-section .mapouter {
    height: 300px;
  }
  .map-section .mapouter .gmap_canvas {
    height: 300px;
  }
  .map-section .mapouter .gmap_canvas iframe {
    height: 300px;
  }
}

.error-area {
  position: relative;
  width: auto;
  height: 100vh;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  background-color: #fff;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  vertical-align: middle;
}

.d-table-cell {
  display: table-cell !important;
}

.error-area .error-content h1 {
  margin-bottom: 25px;
  font-size: 100px;
  font-weight: 800;
  line-height: 100px;
  color: $blue;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-area .error-content h1 {
    font-size: 60px;
    line-height: 50px;
  }
}

@media (max-width: 767px) {
  .error-area .error-content h1 {
    font-size: 45px;
    line-height: 30px;
  }
}

.error-area .error-content h2 {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 700;
  color: #081828;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-area .error-content h2 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .error-area .error-content h2 {
    font-size: 18px;
  }
}

.error-area .error-content p {
  margin-bottom: 40px;
  font-weight: 400;
  color: #888;
}

.maill-success {
  max-width: 800px;
  height: 100vh;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  vertical-align: middle;
}

.d-table-cell {
  display: table-cell !important;
}

.maill-success .success-content i {
  margin-bottom: 20px;
  font-size: 40px;
  color: $blue;
}

.maill-success .success-content h1 {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 700;
  color: $blue;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .maill-success .success-content h1 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .maill-success .success-content h1 {
    font-size: 22px;
  }
}

.maill-success .success-content h2 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #081828;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .maill-success .success-content h2 {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .maill-success .success-content h2 {
    font-size: 15px;
  }
}

.maill-success .success-content p {
  margin-bottom: 20px;
  font-weight: 400;
}

.footer {
  position: relative;
  background-color: #081828;
}

.footer .footer-top {
  padding: 100px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-top {
    padding: 80px 0;
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .footer .footer-top {
    padding: 60px 0;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .single-footer {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .footer .single-footer {
    margin-top: 40px;
    text-align: center;
  }
}

.footer .single-footer.f-about {
  padding-right: 30px;
}

@media (max-width: 767px) {
  .footer .single-footer.f-about {
    padding: 0;
  }
}

.footer .single-footer.f-about .logo img {
  width: 180px;
}

.footer .single-footer.f-about p {
  margin-top: 20px;
  font-size: 14px;
  color: #d2d6dc;
}

.footer .single-footer.f-about .social {
  margin-top: 30px;
}

.footer .single-footer.f-about .social li {
  display: inline-block;
  margin-right: 17px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .single-footer.f-about .social li {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .footer .single-footer.f-about .social li {
    margin-bottom: 10px;
  }
}

.footer .single-footer.f-about .social li:last-child {
  margin: 0;
}

.footer .single-footer.f-about .social li a {
  font-size: 15px;
  color: #d2d6dc;
}

.footer .single-footer.f-about .social li a:hover {
  color: $blue;
}

.footer .single-footer.f-about .social li:last-child {
  margin: 0;
}

.footer .single-footer.f-about .copyright-text {
  margin-top: 40px;
  font-size: 14px;
  color: #d2d6dc;
}

@media (max-width: 767px) {
  .footer .single-footer.f-about .copyright-text {
    margin-top: 20px;
  }
}

.footer .single-footer.f-about .copyright-text a {
  color: #d2d6dc;
}

.footer .single-footer.f-about .copyright-text a:hover {
  color: $blue;
}

.footer .single-footer.f-link li {
  display: block;
  margin-bottom: 12px;
}

.footer .single-footer.f-link li:last-child {
  margin: 0;
}

.footer .single-footer.f-link li a {
  font-size: 15px;
  font-weight: 400;
  color: #d2d6dc;
}

.footer .single-footer.f-link li a:hover {
  color: $blue;
}

.footer .single-footer h3 {
  display: block;
  margin-bottom: 35px;
  font-size: 18px;
  font-weight: 600;
  color: #d2d6dc;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .single-footer h3 {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .footer .single-footer h3 {
    margin-bottom: 25px;
  }
}

.footer .footer-newsletter {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter {
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter {
    padding-bottom: 60px;
    text-align: center;
  }
}

.footer .footer-newsletter .inner-content {
  padding: 50px;
  border: 2px solid rgba(238, 238, 238, .171);
  border-radius: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .inner-content {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .inner-content {
    padding: 30px;
  }
}

.footer .footer-newsletter .title {
  position: relative;
}

.footer .footer-newsletter .title h3 {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #d2d6dc;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .title h3 {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .title h3 {
    font-size: 16px;
  }
}

.footer .footer-newsletter .title p {
  font-size: 15px;
  color: #d2d6dc;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .title p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .title p {
    font-size: 14px;
  }
}

.footer .footer-newsletter .title p a {
  color: $blue;
  text-decoration: underline;
}

.footer .footer-newsletter .title p a:hover {
  color: #fff;
}

.footer .footer-newsletter .newsletter-form {
  position: relative;
}

@media (max-width: 767px) {
  .footer .footer-newsletter .newsletter-form {
    margin-top: 30px;
  }
}

.footer .footer-newsletter .newsletter-form input {
  display: inline-block;
  float: right;
  width: 300px;
  height: 48px;
  padding: 0 20px;
  margin-right: 148px;
  font-size: 14px;
  color: #fff;
  color: #fff !important;
  background: 0 0;
  background-color: #fff3;
  border: none;
  border-radius: 0;
  border-radius: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .newsletter-form input {
    width: 100%;
    padding-right: 144px;
    margin: 0;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .newsletter-form input {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}

.footer .footer-newsletter .newsletter-form ::input-placeholder {
  color: #fff;
  opacity: 1;
}

.footer .footer-newsletter .newsletter-form :input-placeholder {
  color: #fff;
  opacity: 1;
}

.footer .footer-newsletter .newsletter-form ::input-placeholder {
  color: #fff;
  opacity: 1;
}

.footer .footer-newsletter .newsletter-form ::placeholder {
  color: #fff;
  opacity: 1;
}

.footer .footer-newsletter .newsletter-form :input-placeholder {
  color: #fff;
}

.footer .footer-newsletter .newsletter-form ::input-placeholder {
  color: #fff;
}

.footer .footer-newsletter .newsletter-form .button {
  position: absolute;
  right: 0;
  display: inline-block;
}

@media (max-width: 767px) {
  .footer .footer-newsletter .newsletter-form .button {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 10px;
  }
  .footer .footer-newsletter .newsletter-form .button .btn {
    width: 100%;
  }
}

.footer .footer-newsletter .newsletter-form .button .btn {
  display: inline-block;
  height: 48px;
  padding: 0 30px;
  line-height: 48px;
  color: #fff;
  background: $blue;
}

.footer .footer-newsletter .newsletter-form .button .btn::before {
  background-color: #fff;
}

.footer .footer-newsletter .newsletter-form .button .btn:hover {
  color: $blue;
  background-color: #fff;
}
